/* Use standalone tailwindcss version as we have to also use html from firestore */
/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-appGreen {
  color: #00843D !important;
}
.bg-appGreen {
  background: #00843D !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
